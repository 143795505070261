<template>
  <base-card pointed>
    <base-card-part-title-logos
      :logo="getNameLogoUrl"
      :name="getName"
      slot="card-title"
    />
    <base-card-part-logo
      v-if="getLogo"
      :url="getLogo"
      :alt="getReviewType"
      slot="card-logo"/>
    <plain-content
      :title="getReviewType"
      :count-view="value.views"
      :time="getDateTime"
      :is-wide-description="!getLogo"
    >
      {{value.message}}
    </plain-content>
  </base-card>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardPartLogo from '@/components/base/card/part/BaseCardPartLogo'
import BaseCardPartTitleLogos from '@/components/base/card/part/title/BaseCardPartTitleLogos'
import PlainContent from '@/components/misc/content/PlainContent'

export default {
  name: 'ReviewsItemAboutUs',
  components: {
    PlainContent,
    BaseCardPartLogo,
    BaseCardPartTitleLogos,
    BaseCard
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: undefined,
          type: { caption: undefined },
          views: undefined,
          message: undefined,
          proto: { insert: { dtTm: undefined } },
          logo: { url: undefined },
          member: {
            id: undefined,
            surname: undefined,
            firstname: undefined,
            middlename: undefined,
            logo: { url: undefined }
          }
        }
      }
    }
  },
  computed: {
    getName () {
      return `${this.value.member?.surname} ${this.value.member?.firstname}`
    },
    getNameLogoUrl () {
      return this.value.member?.logo?.url
    },
    getLogo () {
      return this.value?.logo?.url
    },
    getReviewType () {
      const reviewTypeCaption = this.value?.type?.caption || 'REVIEW'
      return this.$t(`PORTAL.SYSTEM.REVIEWS.TYPE.${reviewTypeCaption}`)
    },
    getDateTime () {
      return this.value.proto?.insert?.dtTm
    }
  }
}
</script>
